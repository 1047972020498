import { InMemoryCache, makeVar } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { GridFilterItem } from '@mui/x-data-grid'

import { Role } from 'roles'

import { UserFieldsFragment } from 'graphql/user/UserFields.gen'

import { DateModifiedFilter } from 'components/search/SearchProvider'

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        projects: relayStylePagination(),
        projectListingOwnedByFilter: {
          read() {
            return projectListingOwnedByFilterVar()
          },
        },
        projectListingModifiedByFilter: {
          read() {
            return projectListingModifiedByFilterVar()
          },
        },
        projectListingSharedWithFilter: {
          read() {
            return projectListingSharedWithFilterVar()
          },
        },
        projectListingNameFilter: {
          read() {
            return projectListingNameFilterVar()
          },
        },
        projectListingKeywordFilter: {
          read() {
            return projectListingKeywordFilterVar()
          },
        },
        projectListingDateModifiedFilter: {
          read() {
            return projectListingDateModifiedFilterVar()
          },
        },
        projectListingValueFilter: {
          read() {
            return projectListingValueFilterVar()
          },
        },
        projectListingIsOpenFilter: {
          read() {
            return projectListingIsOpenFilterVar()
          },
        },
        projectListingAccountIdFilter: {
          read() {
            return projectListingAccountIdFilterVar()
          },
        },
        projectListingOwnerFilter: {
          read() {
            return projectListingOwnerFilterVar()
          },
        },
        userListingAccountFilter: {
          read() {
            return userListingAccountFilterVar()
          },
        },
        userListingRoleFilter: {
          read() {
            return userListingRoleFilterVar()
          },
        },
        userListingScoreFilter: {
          read() {
            return userListingScoreFilterVar()
          },
        },
        playersListOrderByFilter: {
          read() {
            return playersListOrderByFilterVar()
          },
        },
        // Learning
        videoListingCategoryFilter: {
          read() {
            return videoListingCategoryFilterVar()
          },
        },
        documentListingCategoryFilter: {
          read() {
            return documentListingCategoryFilterVar()
          },
        },
        lv1UsersListingCategoryFilterVar: {
          read() {
            return lv1UsersListingCategoryFilterVar()
          },
        },
      },
    },
  },
})

export enum OwnedByFilterOptionTypes {
  BY_ANYONE = 'by-anyone',
  BY_ME = 'by-me',
  NOT_BY_ME = 'not-by-me',
}

export enum ModifiedByFilterOptionTypes {
  LAST_MODIFIED = 'last-modified',
  LAST_MODIFIED_BY_ME = 'last-modified-by-me',
  LAST_OPENED_BY_ME = 'last-opened-by-me',
}

export enum PlayersOrderByFilterOptionTypes {
  EMAIL_ASC = 'email-asc',
  EMAIL_DESC = 'email-desc',
}

export enum RoleFilterOptionTypes {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export const projectListingOwnedByFilterVar = makeVar<OwnedByFilterOptionTypes>(
  OwnedByFilterOptionTypes.BY_ANYONE,
)

export const projectListingModifiedByFilterVar = makeVar<ModifiedByFilterOptionTypes>(
  ModifiedByFilterOptionTypes.LAST_OPENED_BY_ME,
)

export const playersListOrderByFilterVar = makeVar<PlayersOrderByFilterOptionTypes>(
  PlayersOrderByFilterOptionTypes.EMAIL_ASC,
)

// Project Listing
export const projectListingNameFilterVar = makeVar<string>('')
export const projectListingKeywordFilterVar = makeVar<string>('')
export const projectListingDateModifiedFilterVar = makeVar<DateModifiedFilter>('anytime')
export const projectListingValueFilterVar = makeVar<[string, string]>(['0', '0'])
export const projectListingIsOpenFilterVar = makeVar<boolean>(false)
export const projectListingAccountIdFilterVar = makeVar<string>('')
export const projectListingOwnerFilterVar = makeVar<string | 'anyone'>('anyone')
export const projectListingSharedWithFilterVar = makeVar<GridFilterItem>({
  field: 'sharedWithUsers',
  operator: 'isAnyOf',
  value: [],
})

// User Listing
export const userListingAccountFilterVar = makeVar<Set<UserFieldsFragment['accountId']>>(new Set())
export const userListingRoleFilterVar = makeVar<Set<Role>>(new Set())
export const userListingScoreFilterVar = makeVar<number>(30000)
// Learning
export const videoListingCategoryFilterVar = makeVar<GridFilterItem>({
  field: 'category',
  operator: 'isAnyOf',
  value: [],
})
export const documentListingCategoryFilterVar = makeVar<GridFilterItem>({
  field: 'category',
  operator: 'isAnyOf',
  value: [],
})

export const lv1UsersListingCategoryFilterVar = makeVar<GridFilterItem>({
  field: 'gameLv1Subgroup',
  operator: 'isAnyOf',
  value: [],
})

export const resetProjectListingVars = () => {
  // Reset apollo vars
  // Project Listing
  projectListingNameFilterVar('')
  projectListingKeywordFilterVar('')
  projectListingDateModifiedFilterVar('anytime')
  projectListingValueFilterVar(['0', '0'])
  projectListingIsOpenFilterVar(false)
  projectListingAccountIdFilterVar('')
  projectListingOwnerFilterVar('anyone')
  projectListingSharedWithFilterVar({
    field: 'sharedWithUsers',
    operator: 'isAnyOf',
    value: [],
  })
}
