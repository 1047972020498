import { ReactNode } from 'react'

export type LayoutProps = {
  pageTitle?: string
  children: ReactNode
  isFullscreen?: boolean
  isArchived?: boolean
}

export const createPageTitle = (suffix = 'MakerConnect', title?: string) => {
  if (title) {
    return `${title} | ${suffix}`
  }
  return suffix
}
